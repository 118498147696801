<template>
  <a-layout class="change-password">
    <TitleBreadcrumb />
    <div class="content-base container">
      <a-row type="flex" justify="center">
        <a-col :md="10">
          <ChangePasswordForm />
        </a-col>
      </a-row>
    </div>
  </a-layout>
</template>
<script>
import loadingMixin from "@/mixins/loadingMixin";
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb";
import ChangePasswordForm from "@/components/ChangePasswordForm.vue";
import { pageTitle } from "../../utils/utils";
import labels from "@/utils/labels";
export default {
  components: { TitleBreadcrumb, ChangePasswordForm },
  name: "ChangePassword",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.changePasswordForm.title),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.changePasswordForm.title,
      },
    ],
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.change-password {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  @media screen and (max-width: $screen-mobile) {
    max-width: 100% !important;
  }
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
  }
}
</style>
